import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const NavBar = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 51px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

export const Logo = styled.p`
  font-size: 26px;
  padding-top: 9px;
  padding-left: 14px;
  width: 71px;
  height: 37px;
  text-align: left;
  color: #6380FE;
  opacity: 1;
`;

export const NftCount = styled.div`
  max-width: 100%;
  height: 52px;
  color: #4d4d4d;
  text-align: center;
  font-size: 36px;
  font: normal normal 900 42px/61px Zen Maru Gothic;
  letter-spacing: 0.58px;
  opacity: 1;
`;

export const StyledButton = styled.button`
  padding: 11px 102px 10px 104px;
  border-radius: 8px;
  border: none;
  background-color: #6380fe;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  opacity: 1;
  width: 324px;
  height: 56px;
  cursor: pointer;
  box-shadow: 0px 0px 14px #6380FE91;
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  }
`;

export const StyledButtonB = styled.button`
  padding: 11px 102px 10px 104px;
  border-radius: 8px;
  border: none;
  background-color: #777;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  opacity: 1;
  width: 324px;
  height: 56px;
  }
`;

export const StyledButtonF = styled.button`
  margin: 32px 12px;
  border: none;
  background-color: #6380fe;
  font-size: 18px;
  color: #fff;
  opacity: 1;
  width: 324px;
  height: 64px;
  cursor: pointer;
  box-shadow: 0px 0px 14px #6380FE91;
  transition: .1s;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 450px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 71px;
  transition: width 0.5s;
  transition: height 0.5s;
`;

const feedbackFont = {
  color: '#4d4d4d',
};

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const mintAmount = 1;
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintLimit, setMintLimit] = useState(0);
  const [minted, setMinted] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [preSale, setPreSale] = useState("");
  const [ALProof, setALProof] = useState("");
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkPreSale = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .preSaleStart()
      .call()
      .then((receipt) => {
        setPreSale(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkPublicSale = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .publicSaleStart()
      .call()
      .then((receipt) => {
        setPublicSale(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply()
      .call()
      .then((receipt) => {
        setTotalSupply(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMintLimit = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .mintLimit()
      .call()
      .then((receipt) => {
        setMintLimit(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkMinted = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .minted(blockchain.account)
      .call()
      .then((receipt) => {
        setMinted(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const getAL = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account;
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");
      let whitelist = require("./data/al.json");
      let hashedAddresses = whitelist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      //console.log('AL:hasheBefore/'+ address);
      //console.log('AL:hashed/'+ hashedAddress);
      //console.log("AL:proof/" + proof);
      //console.log("AL:root/" + root);
      setALProof(proof);
      dispatch(fetchData(blockchain.account));
    }
  };

  const claimNFT = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`ミント中です。しばらくお待ちください...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .preMint(mintAmount, ALProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗したので再度試してみてください！");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `ミントが成功しました！OpenSeaで確認してみてください！`
        );
        setClaimingNft(false);
        checkMinted();
        checkTotalSupply();
        dispatch(fetchData(blockchain.account));
      });
  };

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPreSale();
    checkMintLimit();
    checkMinted();
    getAL();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
    checkPreSale();
    checkMintLimit();
    checkMinted();
    getAL();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <NavBar>
        <img style={{maxWidth: "71px",maxHeight: "37px", margin: "9px 0px 7px 18px"}}  src="/config/images/logo.png" alt="logo" />
      </NavBar>       
      <NftCount style={{marginTop:"53px"}}
      >
        {totalSupply} <font color="#a7a7a7">/</font> {CONFIG.MAX_SUPPLY}
      </NftCount>

        <s.TextDescription
          style={{height: "21px",marginBottom: "11px", textAlign: "center", backgroundColor: "#fff", fontWeight: "bold",color:"#a7a7a7"}}
        >
          <div style={{display: "inline",margin: "0",fontSize:"15px",fontWeight: "500",fontColor:"#a7a7a7"}}>
            <p style={{display: "inline",paddingRight:"6px",fontColor:"#a7a7a7",letterSpacing: "0.24px"}}>ミントされた枚数</p><p style={{display: "inline",fontColor:"#a7a7a7"}}>/</p><p style={{display: "inline",paddingLeft:"6px",fontColor:"#a7a7a7",letterSpacing: "0.24px"}}>販売枚数</p>
          </div>
        </s.TextDescription>

      <img style={{textAlign: "center"}} src="/config/images/key.png" alt="Key"/>

      <div style={{boxShadow: "0px 3px 6px #00000029" ,marginBottom:"2px", padding:"0px"}}>
          
        <s.TextTitle
          style={{paddingTop:"9px", textAlign: "center", backgroundColor: "#fff", fontWeight: "bold"}}
        >
          About
        </s.TextTitle>

        <s.TextSubTitle
          style={{textAlign: "center"}}
        >
          実証実験について
        </s.TextSubTitle>

          <s.TextDescription
            style={{padding: "17px 30px 20px 30px"}}
          >
            <div>地域の関係人口増加・課題解決に向けて、NFTクリエイターやNFTホルダーの皆様と共に地域共創プロジェクトを検討しており、</div>
            <div>今回はそのプロジェクトにつながる実証実験として、広島県の観光名所や特産物をモチーフにした企画を実施します。</div>
          </s.TextDescription>
      </div>


        <div style={{textAlign: 'center', maxWidth: '100%',background:"#EDEDF4"}}>

          <s.TextTitle
            style={{paddingTop: "19px"}}
          >
            Product
          </s.TextTitle>

          <s.TextSubTitle
            style={{paddingBottom:"25px"}}
          >
            NFTについて
          </s.TextSubTitle>

          <div style={{ width: '100%', overflowX: 'auto' }}>
            <ResponsiveWrapper style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
              <div style={{ padding: "0px 66px 9px 66px" }}>
                <s.TextDescription
                  style={{
                  width: "100%",
                  marginBottom: '10px',
                  textAlign: 'center',
              }}
            >
              Yoshioka
            </s.TextDescription>
            <a href="https://twitter.com/_yoshioka___" target="_blank" rel="noopener">
              <img style = {{maxHeight: "235px"}} src="/config/images/Yoshioka.jpeg" alt="Yoshioka" />
            </a>
          </div>

          <div style={{ padding: "0px 66px 9px 66px" }}>
            <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              カズシ フジイ
            </s.TextDescription>
            <a href="https://twitter.com/kazushi_fujii_" target="_blank" rel="noopener">
              <img style = {{maxHeight: "235px"}} src="/config/images/Fujii.jpeg" alt="Kazushi fuji" />
            </a>
          </div>

          <div style={{ padding: "0px 66px 9px 66px" }}>
            <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              ONIKU kuitai
            </s.TextDescription>
            <a href="https://twitter.com/kuitai_oniku" target="_blank" rel="noopener">
              <img style = {{maxHeight: "235px"}} src="/config/images/ONIKU.jpeg" alt="Oniku kuitai" />
            </a>
          </div>
        </ResponsiveWrapper>

        <div style={{marginBottom: '10px', paddingTop: "24px", fontSize: "57px", height: "87px", color: "#a7a7a7", textAlign: 'center' }}>＋</div>

        <ResponsiveWrapper style={{ marginBottom: "73px", display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <div style={{ padding: "0px 66px 9px 66px" }}>
            <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              牡蠣ピザ
            </s.TextDescription>
            <a href="https://bouncy-soarer-482.notion.site/3e1842bd9dac48a3b712da12f1ad734d" target="_blank" rel="noopener">
              <img src="/config/images/pizza.png" alt="Pizza" />
            </a>
          </div>


          <div style={{ padding: "0px 0px 9px 0px" }}>
            <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '10px',
                textAlign: 'center',
              }}
            >
              飲食店優待特典
            </s.TextDescription>
            <a href="https://bouncy-soarer-482.notion.site/69b72fbda0b347649cf7e39cfdc92b84" target="_blank" rel="noopener">
              <img src="/config/images/tp.png" alt="shop" />
            </a>
          </div>
        </ResponsiveWrapper>
      </div>
          <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '10px',
                textAlign: 'center',
                fontSize:"12px"
              }}
            >
              (販売期限：23年7月4日20:00 ~ 23年7月10日19:59)
          </s.TextDescription>

          {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
  <>
    <s.TextDescription style={{ textAlign: "center", color: "#4d4d4d" }}>
      完売しました!!
    </s.TextDescription>
  </>
) : (
  <>
    {blockchain.account === "" || blockchain.smartContract === null ? (
      <s.Container ai={"center"} jc={"center"}>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            dispatch(connect());
            getData();
          }}
        >
          CONNECT
        </StyledButton>
        {blockchain.errorMsg !== "" ? (
          <>
            <s.SpacerSmall />
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              {blockchain.errorMsg}
            </s.TextDescription>
          </>
        ) : null}
      </s.Container>
    ) : (
      <>
        <s.TextDescription
          style={{ textAlign: "center", color: "var(--accent-text)" }}
        >
          <div style={feedbackFont}>{feedback}</div>
        </s.TextDescription>
        

        {ALProof != "" ? (//1.AL確認
          preSale == true ? (//2.preSale開始確認
            <>
              {minted > mintLimit - 1 ? (
                <>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButtonB
                      disabled={1} //claimingNftPsがtrueなら disabledを表示させる。＝クリックできない
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      ミント済
                    </StyledButtonB>
                  </s.Container>
                </>
              ) : (
                <>
                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <StyledButton
                      disabled={claimingNft ? 1 : 0} //claimingNftがtrueなら disabledを表示させる。＝クリックできない
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFT();
                        getData();
                        checkTotalSupply();
                      }}
                    >
                      {claimingNft ? "MINTING" : "MINT"}
                    </StyledButton>
                  </s.Container>
                </>
              )}
            </>
          ) : (
            <>
              <s.SpacerSmall />
              <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <s.TextDescription>
                  ALを保有されています。販売開始までもうしばらくお待ちください。
                </s.TextDescription>
              </s.Container>
            </>
          )
            ) : (
              <>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <s.TextDescription>
                    ALを保有されていません
                  </s.TextDescription>
                </s.Container>
              </>
            )}
          </>
        )}
      </>
    )}


            <s.SpacerMedium />                 
                    <s.TextDescription
                  style={{ textAlign: "center"}}
                >1NFT={CONFIG.DISPLAY_COST}ETH +gas
                </s.TextDescription>
                <s.TextDescription
                  style={{ paddingTop:"8px",marginBottom:"0px", textAlign: "center"}}
                >3作品の中から、ランダムでミントされます。
                </s.TextDescription>
          <s.TextDescription
              style={{
                width: "100%",
                marginTop: '6px',
                textAlign: 'center',
              }}
          >
              ALをお持ちの方は1人2枚まで購入できます。
          </s.TextDescription>
          <s.TextDescription
              style={{
                width: "100%",
                textAlign: 'center',
                fontSize:"12px",
              }}
          >
              ALをお持ちの方でもし購入できない場合は
          </s.TextDescription>
          <s.TextDescription
              style={{
                width: "100%",
                textAlign: 'center',
                fontSize:"12px",
                padding:"0px 20px"
                
              }}
          >
              下記までお問い合わせください。
          </s.TextDescription>
          <s.TextDescription
              style={{
                width: "100%",
                marginBottom: '30px',
                textAlign: 'center',
                fontSize:"12px",
              }}
            >
              ●問い合わせ先(twitter:@PARDEYinc./mail:info@pardey.land)
          </s.TextDescription>

          <s.TextTitle
            style={{textAlign: "center"}}
          >
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeb90Ne5Orx-2s9MwBkIlQpsjtzC-IcZYRyBgnVpeQo6Gsyew/viewform?usp=sf_link" target="_blank" rel="noopener">
            <StyledButtonF style={{marginTop:"0px"}}>
              アンケートのご協力
            </StyledButtonF>
            </a>
          </s.TextTitle>

      </div>

          <s.TextTitle
            style={{textAlign: "center", backgroundColor: "#fff"}}
          >

            <a href="https://bouncy-soarer-482.notion.site/3e1842bd9dac48a3b712da12f1ad734d" target="_blank" rel="noopener">
            <StyledButtonF>
            牡蠣ピザについて
            </StyledButtonF>
            </a>

            <a href="https://bouncy-soarer-482.notion.site/69b72fbda0b347649cf7e39cfdc92b84" target="_blank" rel="noopener">
            <StyledButtonF>
              広島県提携店舗の優待特典の受け方
            </StyledButtonF>
            </a>

          </s.TextTitle>

          <s.TextSubTitle
          style={{padding:"46px 16px",textAlign: "center", background:"#EDEDF4"}}
        >
          
            <s.TextSubTitle>●プロジェクトパートナー</s.TextSubTitle>
            <s.TextDescription>カズシフジイ、ONIKU kuitai、Yoshioka</s.TextDescription>
            <s.TextDescription style={{marginBottom:"6px"}}>NEO HIROSHIMA BASE、牡蠣若手の会</s.TextDescription>
            <s.TextDescription>ガリバー、ハラぺー、尾花龍一、ブドウちゃん、Miura-Donuts、クリプトン教授、しもんずげーと、OTO、蒼木ゆい、わらいごえ、FUZZ、ZUTTO MAMORU、AEB、SATOSHI MIYACHI、Dreamin' Divers Project 、SG CREW、COVERED PEOPLE、Crypto Beer Punks、MOKUMOKU、NeoTokyoPunks、ONIマPJ、FLOWER LOLITA、AZITO、NARAKU、古事記project、META KAWAII、JUNKeeeeS、META AKITA、tomajoDAO、TheMafiaAnimals、CNF、ENISHI-縁-KAZUSHI DAO、NFT COLOR、HLPeaceDAO</s.TextDescription>
          
        </s.TextSubTitle>
          

        <s.TextSubTitle
          style={{margin:"16px",textAlign: "center"}}
        >
          <div style={{display: "inline",fontSize:"12px"}}>
            <a href="https://bouncy-soarer-482.notion.site/ee157ba4cc49439e916bdaa32b13ff64" target="_blank" rel="noopener" style={{color:"#4D4D4D",textDecoration: "none"}} ><p style={{display: "inline",paddingRight:"16px",fontColor:"#a7a7a7",letterSpacing: "0.24px"}}>利用規約について</p></a>
            <a href="https://bouncy-soarer-482.notion.site/79c6dd2d27bc4c15aab4bf771ad0e88e" target="_blank" rel="noopener" style={{color:"#4D4D4D",textDecoration: "none"}} ><p style={{display: "inline",paddingLeft:"16px",fontColor:"#a7a7a7",letterSpacing: "0.24px"}}>特定商取引法に基づく表記</p></a>
          </div>
        </s.TextSubTitle>

    </s.Screen>
  );
}
export default App;